var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('h1',[_vm._v(" รับโปรโมชั่น ")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.promotions),function(promotion){return _c('v-col',{key:promotion._id,staticClass:"my-3",attrs:{"cols":"12","md":"3"}},[(promotion.promotion_type === 'DEPOSIT'
          || promotion.promotion_type === 'FIRST_DEPOSIT')?_c('div',{staticStyle:{"height":"100%"}},[_c('img',{ref:("PROMOTION-" + (promotion._id)),refInFor:true,attrs:{"width":"100%","src":promotion.banner,"alt":""}}),_c('center',[_c('span',[_vm._v(_vm._s(promotion.promotion_name))]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-info-circle")]),_vm._v(" เงื่อนไขการรับโปรโมชั่น ")],1)]}}],null,true)},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("จำนวนที่รับได้ใน 1 วัน: "+_vm._s(promotion.limit_per_day)+" ครั้ง")]),_c('p',{staticClass:"mb-0"},[_vm._v("ยอดฝากขั้นต่ำ: "+_vm._s(promotion.minimum_deposit)+" บาท")]),_c('p',{staticClass:"mb-0"},[_vm._v("เทิร์นโอเวอร์: "+_vm._s(promotion.turnover)+" เท่า")]),_c('p',{staticClass:"mb-0"},[_vm._v(" จำนวนสูงสุดที่แจก: "+_vm._s(promotion.maximum_give ? ((promotion.maximum_give) + " บาท") : 'ไม่จำกัด')+" ")])])])],1),_c('v-btn',{staticClass:"mt-3",attrs:{"depressed":"","block":"","color":"app-primary"},on:{"click":function($event){return _vm.joinPromotion(promotion._id)}}},[_vm._v(" รับโปรโมชั่น ")])],1):_vm._e(),(promotion.promotion_type === 'FORTUNE_WHEEL')?_c('div',{staticStyle:{"height":"100%"}},[_c('img',{attrs:{"width":"100%","src":promotion.banner,"alt":""}}),_c('center',[_c('span',[_vm._v(_vm._s(promotion.promotion_name))])]),_c('v-btn',{staticClass:"mt-3",attrs:{"depressed":"","block":"","color":"app-primary","to":{ name: 'FortuneWheel' }}},[_vm._v(" เล่นกงล้อ ")])],1):_vm._e(),(promotion.promotion_type === 'REFUND_LOSS')?_c('div',{staticStyle:{"height":"100%"}},[_c('img',{attrs:{"width":"100%","src":promotion.banner,"alt":""}}),_c('center',[_c('span',[_vm._v(_vm._s(promotion.promotion_name))]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-info-circle")]),_vm._v(" เงื่อนไขการรับโปรโมชั่น ")],1)]}}],null,true)},[_c('div',[_c('p',[_vm._v(" รับยอดเสียคืนเดือนละ 1 ครั้งโดยยอดเสียคิดจาก ยอดฝาก - ยอดถอนรวมในเดือนนั้นๆ ")])])])],1),_c('v-btn',{staticClass:"mt-3",attrs:{"depressed":"","block":"","color":"app-primary","to":{ name: 'Cashback' }}},[_vm._v(" รับเครดิตคืน ")])],1):_vm._e()])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }